import {useState} from 'react';
import {Navigate} from 'react-router-dom'
import axios from "../../../config/axiosConfig";



// styles
import "./LoginPage.css";

// translation

import {useUserContext} from "../../../context/userContext/context.js";

const LoginPage = () => {
    const loginBtnStyle = {backgroundColor: "#012453", color: "white"}
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const {getUserData} = useUserContext()
    const [redirect, setRedirect] = useState(false)

    const handleLogin = async (event) => {
        event.preventDefault();
        let response = await axios.post('/loginPanelUser', {email, password})
        setEmail('');
        setPassword('');
        setMessage(response.data.msg);
        switch (response.data.msg) {
            case 'Успешно влизане!':
            case "LoginPage Successful!":
                await getUserData()
               setRedirect(true)
                break
            default:
                console.log(response)
                break
        }
    }
    if (redirect) return <Navigate to={'/'}/>

    return (
            <div className={'loginForm'}>
                <h2>{message}</h2>
                            <input
                                placeholder={"Email/username"}
                                type="text"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <input
                                placeholder={'Password'}
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <button
                                style={loginBtnStyle}
                                title={""}
                                onClick={handleLogin}
                            >Login</button>



            </div>

    )
};

export default LoginPage;