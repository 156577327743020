import React, {useReducer, useContext, createContext} from 'react';

import reducer from './reducer';

import {
    GET_USERS_LIST_ERROR,
    DELETE_USER_ERROR,
    UPDATE_USER_ERROR,
    STORE_USERS_LIST,
    UPDATE_USER_BEGIN,
    CREATE_USER_SUCCESS,
    CREATE_USER_ERROR,
    CREATE_USER_BEGIN,
    GET_LABELS_LIST_ERROR,
    STORE_LABELS_LIST,
    UPDATE_LABEL_BEGIN

} from './actions';

import axios from "../../config/axiosConfig";


const initialState = {
    adminLoading: true,
    users: [],
    labels:[]
}

const AdminContext = createContext();

const AdminProvider = ({children}) => {

    const [state, dispatch] = useReducer(reducer, initialState);

    const getUsers = async () => {

        try {
            // todo get orders from db
            const response = await axios('/getPanelUsersList')


            const users = response.data.users
            dispatch({
                type: STORE_USERS_LIST,
                payload: {
                    users
                },
            })
        } catch (error) {
            console.log(error);
            dispatch({
                type: GET_USERS_LIST_ERROR,
                payload: {
                    error
                },
            })
        }

    }
    const getLabels = async () => {

        try {
            const response = await axios('/getAllLabels')


            dispatch({
                type: STORE_LABELS_LIST,
                payload: {
                    labels:response.data.labels
                },
            })
        } catch (error) {
            console.log(error);
            dispatch({
                type: GET_LABELS_LIST_ERROR,
                payload: {
                    error
                },
            })
        }

    }

    const updateLabelMeta = async (query, update) => {
        dispatch({type: UPDATE_LABEL_BEGIN});
        try {
            const response = await axios(`/updateLabelMeta`, {
                method: "post",
                data: {query, update}
            })
            if (response.data.msg === 'ok') {
                let {updatedLabel}=response.data
                let updatedLabels = [...state.labels.filter(u=>u._id !== updatedLabel._id),updatedLabel]

                dispatch({
                    type: STORE_LABELS_LIST,
                    payload: {
                        labels: updatedLabels
                    },
                })
            }
        } catch (error) {
            console.log(error);
            dispatch({
                type: GET_LABELS_LIST_ERROR,
                payload: {
                    error
                },
            })
        }

    }
    const updateUser = async (query, update) => {
        dispatch({type: UPDATE_USER_BEGIN});
        try {
            const response = await axios(`/updatePanelUser`, {
                method: "post",
                data: {query, update}
            })
            if (response.data.msg === 'ok') {
                let updatedUsers = [...state.users.filter(u=>u._id !== query._id),response.data.updatedUser]

                dispatch({
                    type: STORE_USERS_LIST,
                    payload: {
                        users: updatedUsers
                    },
                })
            }
        } catch (error) {
            console.log(error);
            dispatch({
                type: UPDATE_USER_ERROR,
                payload: {
                    error
                },
            })
        }

    }
    const createNewUser = async (userData) => {
        dispatch({type: CREATE_USER_BEGIN});
        try {
            const response = await axios(`/createNewPanelUser`, {
                method: "post",
                data: userData
            })

            if (response.data._id) dispatch({
                type: CREATE_USER_SUCCESS,
                payload: {
                    users: [...state.users, response.data]
                },
            })
        } catch (error) {
            console.log(error);
            dispatch({
                type: CREATE_USER_ERROR,
                payload: {
                    error
                },
            })
        }

    }

    const deleteUser = async (userId) => {
        try {
            const response = await axios(`/deletePanelUser`, {
                method: "delete",
                data: {userId}
            });
            if (response.status === 200) dispatch({
                type: STORE_USERS_LIST,
                payload: {
                    users: state.users.filter((u) => u._id !== userId)
                },
            })
        } catch (error) {
            console.log(error);
            dispatch({
                type: DELETE_USER_ERROR,
                payload: {
                    error
                },
            })
        }

    }




    return <AdminContext.Provider value={{
        ...state,
        getUsers,
        updateUser,
        deleteUser,
        createNewUser,
        getLabels,updateLabelMeta
    }}>
        {children}
    </AdminContext.Provider>
}


const useAdminContext = () => {
    return useContext(AdminContext);
}

export {AdminProvider, initialState, useAdminContext}